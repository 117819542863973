import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { calculateTimeLeft } from "src/utils";
import { BiTimeFive } from "react-icons/bi";
import { BsClockHistory } from "react-icons/bs";
import { UserContext } from "src/context/User";
import { useHistory, Link as ReactRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  NftImg: {
    maxWidth: "100%",
    height: "250px",
  },
  // bottomblock: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignContent: "center",
  // },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  textField: {
    marginRight: "10px",
    // height:"35px",
  },
  btnbox: {
    "& h6": {
      "&:hover": {
        background: "none",
        border: "1px solid green",
        color: "green",
      },
    },
  },
  currentBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h6": {
      color: "rgba(0, 0, 0, 0.68)",
      fontSize: "14px",
    },
  },
  bottomView: {
    height: "33px",
    minWidth: "80px",
  },

  figureimg: {
    width: "100%",
    margin: "0px",
    maxHeight: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "auto",
      maxWidth: "100%",
      maxHeight: "300px",
    },
  },

  mainimg: {
    width: "100%",
    height: "165px",
    overflow: "hidden",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: " no-repeat !important",
    borderRadius: "10px",
    backgroundColor: "#ccc !important",
  },
}));

export default function ProfileCard(props) {
  const { type, data, rejectBid, index } = props;
  console.log("userdatadata2", data);
  const user = useContext(UserContext);

  const classes = useStyles();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const dateCheck = parseInt(moment(data?.expiryTime).unix()) * 1000;
  const currentDatecheck = parseInt(moment().unix() * 1000);

  // console.log("data?.expiryTime).unix()", parseInt(moment().unix()));

  //Timer

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(
        calculateTimeLeft(
          new Date(parseInt(moment(data?.expiryTime).unix()) * 1000)
        )
      );
    }, 1000);
    return () => clearTimeout(timer);
  });
  // const updateDimensions = () => {
  //   var offsetWidth = document.getElementById("imagecard" + index).offsetWidth;
  //   var newoofsetWidth = offsetWidth - 80;
  //   document.getElementById("imagecard" + index).style.height =
  //     newoofsetWidth + "px";
  // };

  // useEffect(() => {
  //   updateDimensions();
  // }, [data, index]);
  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   return () => window.removeEventListener("resize", updateDimensions);
  // }, []);
  return (
    <Box>
      <Box display="flex ">
        <Box className="collectionSet favourites bgCardcolor">
          <Box
            id={`imagecard${index}`}
            className={classes.mainimg}
            style={{ background: "url(" + data?.nftId?.thumbNails + ")" }}
          ></Box>

          {/* <figure className={classes.figureimg}>
            <img
              className={classes.NftImg}
              src={user?.userData.profilePic}
              width="100%"
              alt=""
            />
          </figure> */}

          <Box style={{ padding: "20px 0 0 0" }}>
            <Box>
              <Typography
                variant="h6"
                className="NftName"
                style={{ color: "rgb(53, 56, 64)" }}
              >
                {data?.nftId?.tokenName}
              </Typography>
            </Box>
            <Box className={classes.currentBox}>
              <Typography
                variant="h6"
                className="NftPrice"
                style={{ color: "#4371c3 !important" }}
              >
                Price :
              </Typography>

              {/* <Typography
                variant="h6"
                className="NftPrice"
                style={{ color: "#000 !important" }}
              >
                <strong style={{ color: "#000" }}> {data.price}</strong>
              </Typography> */}
              {data?.currency === "BNB" ? (
                <>
                  {" "}
                  <Typography
                    variant="h6"
                    style={{
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    <img
                      src="images/bsc-bnb.svg"
                      style={{ width: "14px", height: "14px" }}
                    />
                    &nbsp;{data.price}&nbsp;
                    <span style={{ fontSize: "12px" }}>{data?.currency}</span>
                  </Typography>
                </>
              ) : (
                <>
                  {" "}
                  <Typography
                    variant="h6"
                    style={{
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    {data.price}&nbsp;
                    <span style={{ fontSize: "12px" }}>{data?.currency}</span>
                  </Typography>
                </>
              )}
            </Box>
            <Box align="right" style={{ paddingTop: "4px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <BsClockHistory
                  style={{
                    fontSize: "12px",
                    marginRight: "4px",
                    // color: "#4371c3",
                    color: "rgb(81 80 80)",
                  }}
                />
                {dateCheck > currentDatecheck ? (
                  <span
                    className="bidTime"
                    style={{
                      fontSize: "14px",
                      color: "rgb(81 80 80)",
                    }}
                  >
                    {timeLeft.days ? timeLeft.days && timeLeft.days : "0"}d :{" "}
                    {timeLeft.hours ? timeLeft.hours && timeLeft.hours : "0"}h :{" "}
                    {timeLeft.minutes
                      ? timeLeft.minutes && timeLeft.minutes
                      : "0"}
                    m :{" "}
                    {timeLeft.seconds
                      ? timeLeft.seconds && timeLeft.seconds
                      : "0"}
                    s{/* {Time} */}
                  </span>
                ) : (
                  <Typography style={{ color: "red", fontSize: "0.875rem" }}>
                    Order expired
                  </Typography>
                )}
              </Box>
            </Box>

            {type === "auction" || type === "auctionLowBid" ? (
              <Box mb={1} mt={1} className={classes.bottomblock}>
                <Typography
                  variant="h5"
                  className="NftReserved"
                  style={{ marginBottom: "5px" }}
                ></Typography>
              </Box>
            ) : (
              <Box mb={1} mt={1} className={classes.bottomblock}>
                <Typography
                  variant="h5"
                  className="NftExpire"
                  style={{ marginBottom: "5px" }}
                >
                  <BiTimeFive
                    style={{ fontSize: "13px", marginRight: "5px" }}
                  />
                  Closing in {data?.expireTime}
                </Typography>
              </Box>
            )}
            {type === "auction" ? (
              <Box className={classes.bottomblock}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  label="Enter BNB Amount"
                  className={classes.textField}
                />
                <Button variant="contained" color="secondary" size="medium">
                  Place Bid
                </Button>
              </Box>
            ) : (
              ""
            )}
            {user?.userData?.userType !== "USER" ? (
              type === "auctionLowBid" ? (
                <Box
                  onClick={(e) => {
                    history.push({
                      pathname: "/nft-details",
                      search: data._id,
                    });
                  }}
                >
                  <Box className={classes.btnbox}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      component={ReactRouter}
                      // to="/nft-details"
                      className={classes.bottomView}
                      onClick={(e) => {
                        history.push({
                          pathname: "/nft-details",
                          search: data._id,
                        });
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
              ) : (
                ""
              )
            ) : type === "auctionLowBid" ? (
              <Box
                onClick={(e) => {
                  history.push({
                    pathname: "/mynft-details",
                    search: data._id,
                    data: "orderapi",
                  });
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  // className="NftHighestBid"
                  className={classes.bottomView}
                  style={{ marginBottom: "5px" }}
                >
                  View
                </Button>
              </Box>
            ) : (
              ""
            )}

            {type === "hasOffer" ? (
              <Box className={classes.bottomblock}>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                   
                  >
                    Accept
                  </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => rejectBid(data)}
                >
                  Reject
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
