import React from "react";
import { makeStyles } from "@material-ui/core";
import Footer from "./Footer";
import Header from "./Header";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  mainsec:{
    minHeight:"calc(100vh-100px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      {/* <Header /> */}
      <div className={classes.mainsec}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
