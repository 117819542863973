import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {

    MuiDialog: {
      paperScrollPaper: {
        background: "#f7f7f4",
        overflowY: "hidden",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "16px",
        lineHeight: "26px",
        color: "#000",
        fontWeight: "400",
        "@media (max-width: 767px)": {
          fontSize: "13px",
        },
      },
      h3: {
        color: "#14a800",
        fontSize: "35px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "600",
        lineHeight: "40px",
        // "@media (min-width: 767px)":{
        //   fontSize: "25px",
        // },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#fff",
        border: "1px solid #fff",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        "&:hover": {
          backgroundColor: "#fff",
        },
        "&$focused": {
          backgroundColor: "#fff",
        },
      },
      underline: {
        "&:before": {
          // borderBottomColor: "red"
        },
        "&:hover:not(.Mui-focused):before": {
          // borderBottomColor: "green"
        },
        "&:after": {
          // focused
          // borderBottomColor: "purple"
        },
      },
    },

    MuiFormControl: {
      root: {
        "&:focus-visible": {
          border: "1px solid #14a800 !important",
          outlineColor: "#14a800 !important",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:focus-visible": {
          border: "1px solid #14a800 !important",
          outlineColor: "#14a800 !important",
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        color: "#35373a",
        height: "auto",
        overflow: "hidden",
        letterSpacing: "1px",
        fontSize: "14px",
        fontWeight: "400",
        height: "0.1876em",
        minHeight: "12px !important",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "@media (max-width: 767px)": {
          fontSize: "16px",
        },
      },
      select: {
        marginBottom: "5px"
      },
      icon: {
        color: "#808080",
        // right: " 12px",
        // position: "absolute",
      },
    },
    MuiInput: {
      // padding:'7px 8px',
      underline: {
        "&:before": {
          left: "0",
          right: "0",
          bottom: "0",
          content: '"\\00a0"',
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "none",
          pointerEvents: "none",
          display: "none"
        },
        "&:after": {
          left: "0",
          right: "0",
          bottom: "0",
          content: " ",
          position: "absolute",
          transform: "scaleX(0)",
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          borderBottom: "none",
          pointerEvents: "none",
          display: "none",
        }
      },

      root: {
        marginTop: "5px",
        // marginRight: " 25px",
        paddingLeft: "3px",
        color: "#36383c",
        // "@media (max-width: 991px)": {
        //   padding:"14px",
        //   width:"100%",
        //   borderBottom: "1px solid #4270c2",
        //  },

        "@media (max-width: 820px)": {
          padding: "14px",
          width: "100%",
          borderBottom: "1px solid #4270c2",
        },
      },
    },
    MuiInputBase: {
      root: {
        height: "40px",
        // padding:'7px 8px',
        color: "#f5f5f5",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px"
      },
    },
    // MuiBox: {
    //   root: {
    //     padding: "0px",
    //   },
    // },

    MuiInputBase: {
      root: {
        marginRight: "5px",
      },
      input: {
        font: "inherit",
        color: "#000",
        width: "100%",
        minHeight: "12px !important",
        border: "0",
        height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
      },
    },
    MuiPopover: {
      paper: {
        padding: "7px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(7px, 13px) scale(1)",
        fontSize: "14px",
        fontWeight: "500",
      },
      filled: {
        color: "#fff",
        "&$focused": {
          color: "#fff",
        },
        ".MuiFormControl-root:hover &:not($focused)": {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: "14px",
      },
      multiline: {
        height: "auto",
      },
      inputMarginDense: {
        paddingTop: "14.5px",
        paddingBottom: "14.5px",
      },
    },

    MuiDropzoneArea: {
      root: {
        height: "175px",
        minHeight: "175px",
        marginBottom: "15px",
        borderColor: "#46484bb8 !important",

        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
        fontWeight: 500,
        fontSize: "16px",
        padding: "2px 25px",
        margin: "10px 0 15px",
      },
      barColorPrimary: {
        backgroundColor: "#4371c3",
      },
      colorPrimary: {
        backgroundColor: "#efefef",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        backgroundColor: "#fff",
        overflowY: "hidden",
      },
    },
    MuiButton: {
      label: {
        // marginRight: "-9px",
      },
      root: {
        fontSize: "14px",
        height: "38px",
        minWidth: "auto",
        borderRadius: 5,
        fontWeight: "500",
        "@media (max-width: 600px)": {
          height: "35px",
          fontSize: "16px",
          borderRadius: 3,
        },
      },
      // textPrimary:{
      //   color:"#000",
      // },
      containedPrimary: {
        boxShadow: "none",
        height: "38px",
        backgroundColor: "red",
        "&:hover": {
          boxShadow: "none",
          color: "#da1d1d",
          backgroundColor: "#fff",
        },
      },
      outlinedSecondary: {
        color: "#ffffff",
        border: "1px solid #4371c3",
        height: "50px",
        fontSize: "15px !important",
        maxWidth: "153px",
        background: "#4371c3",
        fontWeight: "600",
        lineHeight: "16px",
        borderRadius: "50px",
        "&:hover": {
          boxShadow: "none",
          background: "red",
          backgroundColor: "#1ed760",
        },
        "@media (max-width: 600px)": {
          padding: "5px 5px",
          fontSize: "16px",
        },
      },
      containedSizeLarge: {
        color: "#fff",
        fontSize: "20px",
        padding: "10px 59px",
        background: "#1ed760",
        "@media (max-width: 600px)": {
          padding: "5px 25px",
          fontSize: "16px",
        },
      },
      containedSizeSmall: {
        height: "40px",
        fontSize: "16px",
      },
      outlined: {
        boxShadow: "none",
        borderWidth: 2,
      },
      outlinedPrimary: {
        color: "#ffffff",
        border: "1px solid #1ed760",
        height: "50px",
        fontSize: "15px !important",
        maxWidth: "153px",
        background: "#1ed760",
        fontWeight: "600",
        lineHeight: "16px",
        borderRadius: "50px",
        "&:hover": {
          boxShadow: "none",
          border: "none",
          backgroundColor: "#4371c3",
        },
      },
      outlinedSizeLarge: {
        width: "100%",
        height: "45px",
        fontSize: "0.9375rem",
        // maxWidth: "140px",
        "@media (max-width: 600px)": {
          fontSize: "16px",
          height: "37px",
          width: "max-content",
        },
      },
    },

    MuiPaginationItem: {
      textPrimary: {
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: "#25ab2561 !important",
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        display: "table-cell",
        padding: "16px",
        fontSize: "16px",
        textAlign: "left",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "400",
        lineHeight: "1.43",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        verticalAlign: "inherit"
      },
      alignLeft: {
        textAlign: "center",
      },
      stickyHeader: {
        backgroundColor: "#f5f5f5",
        fontWeight: "600",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#35373a",
        width: "auto",
        padding: "6px",
        overflow: "hidden",
        fontSize: "14px",
        boxSizing: "border-box",
        minHeight: "48px",
        fontWeight: "400",
        lineHeight: "1.5",
        paddingTop: "6px",
        whiteSpace: "nowrap",
        letterSpacing: "0.00938em",
        paddingBottom: "6px",
      },
    },
    MuiPaper: {
      root: {
        color: "#000",
      },
      rounded: {
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
      },
      outlined: {
        backgroundColor: "#121212",
        padding: "10px",
        borderRadius: "0",
        border: "0",
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 500,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#858585",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#1ed760",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
