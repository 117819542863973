import React, { useContext, useState, useEffect } from "react";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import {
  Container,
  Typography,
  Box,
  Grid,
  Link,
  Divider,
  Button,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ProfileCard from "src/component/ProfileCard";
import Slider from "react-slick";
import { UserContext } from "src/context/User";
import { Link as RouterLink, useHistory } from "react-router-dom";
import axios from "axios";
import Apiconfigs from "../../../Apiconfig/Apiconfigs";
import { NftTokenAddress, OpenMarketplace } from "../../../constants";
import OpenMarketplaceABI from "../../../abis/OpenMarketplaceABI";
import { useWeb3React } from "@web3-react/core";
import Pagination from "@material-ui/lab/Pagination";

const colelctionDetails = [];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bannerpic: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "250px",
    overflow: "hidden",
    borderRadius: "15px",
    background: "rgba(0,0,0,.07)",
  },
  wallet: {
    background: "#fff",
    padding: "90px 0",
  },

  iconSocial: {
    ontSize: "15px",
    color: "#999999",
    marginRight: "8px",
  },
  dataIcon: {
    fontSize: "18px",
    color: "#999999",
  },
  root: {
    display: "flex",
    justifyContent: "end",
  },
  dataNumber: {
    float: "right",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#999999",
  },
  walletSet: {
    padding: "0 15px 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
    },
  },
  sectionTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
  },
  imgheight: {
    height: "200px",
  },
  editbtnbox: {
    fontSize: "22px",
    color: "#000",
    // marginRight: "10px",
    "&:hover": {
      color: "green",
    },
  },

  NftImg: {
    borderRadius: 10,
    boxShadow: "0 11px 24px rgb(0, 0, 0, 0.12)",
    display: "block",
    miHeight: "300px",
    position: "relative",
  },

  profileBox: {
    "& .TabButtonsBox": {
      marginBottom: "25px",
      "& button": {
        color: "#ccc",
        borderBottom: "2px solid transparent",
        borderRadius: "0",
        "&.active": {
          color: "#1FA5A2",
          borderBottom: "2px solid #1FA5A2",
        },
      },
    },
    "& .figure": {
      width: "100%",
      overflow: "hidden",
      height: "250px",
      margin: 0,
      borderRadius: "10px",
      "& img": {
        width: "100%",
      },
    },
    "& .usersPic": {
      background: " #383858",
      padding: "20px",
      marginTop: " -150px",
      textAlign: "center",
      borderRadius: "10px",
      "& figure": {
        width: "150px",
        height: "150px",
        marginBottom: "20px",
        borderRadius: "50%",
        margin: "auto",
        overflow: "hidden",
        "& img": {
          width: "100%",
          height: "100%",
        },
      },
    },
  },
  profilemainBox: {
    paddingLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  mainimg: {
    width: "100%",
    height: "165px",
    overflow: "hidden",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: " no-repeat !important",
    borderRadius: "10px",
    backgroundColor: "#ccc !important",
  },
  bannerimg: {
    overflow: "hidden",
    backgroundPosition: "center !important",
    backgroundSize: "100% !important",
    backgroundRepeat: " no-repeat !important",
    height: "260px",
    borderRadius: "10px",
    "@media(max-width:1010px)": {
      height: "140px",
      borderRadius: "10px",
    },
    "& img": {
      minHeight: "100%",
      minWidth: "100%",
      height: "auto",
      width: "auto",
    },
  },
  nametext: {
    "& h6": {
      color: "#1d1f22",
      wordBreak: "break-all",
    },
  },
  descriptiontext: {
    "& h6": {
      fontSize: "14px",
      color: "#1d1f22",
      wordBreak: "break-all",
    },
  },
}));
export default function Profile(props) {
  const user = useContext(UserContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataNot, setdataNot] = React.useState("");
  const [pages, setpages] = useState(1);
  const [numpages, setNumpages] = useState(1);
  const [orderList, setOrderList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [errpopup, seterrpopup] = React.useState(false);
  const [errmsg, seterrmsg] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const classes = useStyles();
  const { account, library } = useWeb3React();
  const errhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterrpopup(false);
  };
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    getDataHandler();
    getbidListHandler();
    getProfileHandler();
  }, [account, pages]);
  //ADMIN
  const getDataHandler = async () => {
    setIsLoading(true);
    setdataNot("");
    try {
      const res = await axios.get(Apiconfigs.placeOrderListt, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
        params: {
          limit: 20,
          page: pages,
        },
      });
      if (res.data.response_code === 200) {
        setOrderList(res.data.result.docs);
        setNumpages(res.data.result.pages);
        setIsLoading(false);
      } else {
        setdataNot("Data not found");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  //USER//USE
  const getbidListHandler = async () => {
    setIsLoading(true);
    setdataNot("");
    try {
      await axios({
        method: "GET",
        url: Apiconfigs.myOrderList,
        // url: Apiconfigs.myNftList,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          limit: 20,
          page: pages,
        },
      })
        .then(async (res) => {
          if (res.data.response_code === 200) {
            setBidList(res.data.result.docs);
            setNumpages(res.data.result.pages);
            setIsLoading(false);
          } else {
            setdataNot("Data not found");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err.message);
        });
    } catch (error) {
      setIsLoading(false);

      console.log("error", error);
    }
  };

  const getProfileHandler = async () => {
    try {
      const res = await axios.get(Apiconfigs.getprofile, {
        headers: {
          token: token,
        },
      });
      if (res.data.response_code === 200) {
        setUserData(res.data.result);
        console.log("re-muk", res.data.result);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const filterdata = () => {
    if (userData?.userType !== "USER") {
    } else {
    }
  };

  useEffect(() => {}, [userData]);

  return (
    <Box className={classes.wallet}>
      <Container maxWidth="lg">
        <Box>
          <Box
            className={classes.bannerimg}
            style={{
              background:
                "url(" +
                `${
                  userData && userData?.coverImage
                    ? userData.coverImage
                    : "images/Profilebg.png"
                }` +
                ")",
            }}
          ></Box>
          <Container maxWidth="sm">
            <Box className="Profilepicicon">
              <figure>
                <img
                  src={
                    userData && userData?.profilePic
                      ? userData.profilePic
                      : "images/imagesavatar.png"
                  }
                  className="img-responsive"
                  alt=""
                />
                <img
                  src="images/check.png"
                  ClassName="checkiconpicimage"
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "4px",
                    height: "auto",
                    width: "auto",
                  }}
                  alt=""
                />
              </figure>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6">Name</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6">:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box className={classes.descriptiontext}>
                      <Typography variant="h6">
                        {userData && userData?.userName
                          ? userData?.userName
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6">Email</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6">:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box className={classes.descriptiontext}>
                      <Typography variant="h6">
                        {userData && userData?.email ? userData?.email : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6"> Bio</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Box className={classes.nametext}>
                      <Typography variant="h6">:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box className={classes.descriptiontext}>
                      <Typography
                        variant="h6"
                        style={{ wordBreak: "break-all" }}
                      >
                        {userData && userData?.bio ? userData?.bio : "N/A"}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box align="center" mt={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() =>
                    history.push({
                      pathname: "/edit-profile",
                      state: { data: userData },
                    })
                  }
                >
                  Edit Profile
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box mt={3} mb={2}>
          <Box align="left" mt={7}>
            <Typography variant="h3">My Orders</Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box>
            <Box className={classes.sectionTitleHead}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  color: "#222",
                }}
              >
                Displaying{" "}
                {userData?.userType !== "USER"
                  ? orderList.length
                  : bidList.length}{" "}
                NFT's
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2} style={{ display: "block" }}>
            <Grid container>
              <Box className={classes.sectionTitleHead}></Box>
            </Grid>
            {/* {dataNot} */}
            {isLoading ? (
              <Box display="flex" alignItems="center" ml={2}>
                <h4>Loading....</h4> <ButtonCircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2}>
                {userData?.userType !== "USER"
                  ? orderList.map((data, i) => {
                      return data.bidPrice > data.reservePrice ? (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={i}
                          className="walletSet"
                        >
                          <ProfileCard data={data} type="auction" index={i} />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          lg={3}
                          key={i}
                          className="walletSet"
                        >
                          <ProfileCard
                            data={data}
                            type="auctionLowBid"
                            index={i}
                          />
                        </Grid>
                      );
                    })
                  : bidList.map((data, i) => {
                      return data.bidPrice > data.reservePrice ? (
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          lg={3}
                          key={i}
                          className="walletSet"
                        >
                          <ProfileCard data={data} type="auction" index={i} />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          lg={3}
                          key={i}
                          className="walletSet"
                        >
                          <ProfileCard
                            data={data}
                            type="auctionLowBid"
                            index={i}
                          />
                        </Grid>
                      );
                    })}
                <Box ml={2}>
                  {userData?.userType !== "USER" ? (
                    <>
                      {orderList && orderList.length === 0 && "No data found"}
                    </>
                  ) : (
                    <>{bidList && bidList.length === 0 && "No data found"}</>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Snackbar
          open={errpopup}
          autoHideDuration={6000}
          onClose={errhandleClose}
        >
          <Alert onClose={errhandleClose} severity="info">
            {errmsg}
          </Alert>
        </Snackbar>
        {/* {details.length != 0 ? ( */}
        <Box className={classes.root} pt={3}>
          <Pagination
            onChange={(e, v) => setpages(v)}
            count={parseInt(numpages)}
            color="primary"
          />
        </Box>
        {/* ) : (
          ""
        )} */}
      </Container>
    </Box>
  );
}
