import React, { useEffect, useState } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import Apiconfigs from "../../Apiconfig/Apiconfigs";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: "#252525",
    background: "#1c2b46!important",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "90px 0",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },

  logoContent: {
    display: "flex",
    width: "30%",
  },
  listitm: {
    color: "#ffffff",
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    "& svg": {
      color: "#ffffff",
      fontSize: "18px",
    },
    "&:hover": {
      color: "#14a800",
    },
  },
  footerSectionimg: {
    width: "80%",
    "& img": {
      width: "85%",
    },
  },
  logotext: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  testfooter: {
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "17px",
    },
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    maxWidth: "180px",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      justifyContent: "space-between",
    },
  },
  orgBox: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  const classes = useStyles();
  const myStyle = {
    marginLeft: "20%",
    marginTop: "2%",
    color: "#ffffff",
  };
  const [FaqDataList, setBidList] = useState([]);

  const [dataNot, setdataNot] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [youtube, setYoutubeLink] = useState([]);
  const [facebook, setFaebookLink] = useState([]);
  const [twitter, setTwitterLink] = useState([]);
  const [telegram, setTelegramLink] = useState([]);
  const listStaticContent = async () => {
    setdataNot("");
    try {
      setIsLoading(true);
      await axios({
        method: "GET",
        url: Apiconfigs.getLink,
      })
        .then(async (res) => {
          if (res.data.response_code === 200) {
            setIsLoading(false);
            setBidList(res.data.result);
            const YoutubeLink = res.data.result.filter(
              (data) => data.Type.trim() === "YoutubeLink"
            );
            setYoutubeLink(YoutubeLink[0]);
            const FaebookLink = res.data.result.filter(
              (data) => data.Type.trim() === "FacebookLink"
            );

            setFaebookLink(FaebookLink[0]);
            const TwitterLink = res.data.result.filter(
              (data) => data.Type.trim() === "TwitterLink"
            );

            setTwitterLink(TwitterLink[0]);
            const TelegramLink = res.data.result.filter(
              (data) => data.Type.trim() === "TelegramLink"
            );

            setTelegramLink(TelegramLink[0]);

            // setNumpages(res.data.result.pages);
          } else {
            setdataNot("Data not found");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  useEffect(() => {
    listStaticContent();
  }, []);

  return (
    <>
      <Box className={classes.footerSection}>
        <Container>
          <Box className={classes.footerSectionBelow}>
            <Grid container spacing={4}>
              <Grid item lg={4} sm={4} xs={12}>
                <Box alignItems="left" className={classes.logotext}>
                  <Box width="150px" className={classes.logotext}>
                    <Box>
                      <img
                        src="/images/ccc.gif"
                        alt="Logo"
                        width="100%"
                        style={{ cursor: "pointer", maxWidth: "70%" }}
                        onClick={() => history.push("/")}
                      />
                    </Box>
                  </Box>
                  <Box pl={2}>
                    {" "}
                    <Typography
                      variant="h6"
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                      }}
                    >
                      Culture
                      <br /> Creative
                      <br /> Communities
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant="h6"
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                    }}
                  >
                    CCC is an abbreviation of the core thematic words culture,
                    creative and community.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} sm={4} xs={6} align="center">
                <Box className={classes.orgBox}>
                  <Box align="left">
                    <Typography variant="h5" className={classes.testfooter}>
                      Organization
                    </Typography>
                    <Box style={{ paddingTop: "15px" }}>
                      {/* <RouterLink
                        component={Link}
                        to="/legal-policy"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.listitm}>
                          Grant Program Terms
                        </Typography>
                      </RouterLink> */}
                      <RouterLink
                        component={Link}
                        to="/terms-conditions"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.listitm}>
                          Terms Of Use
                        </Typography>
                      </RouterLink>
                      <RouterLink
                        component={Link}
                        to="/disclaimer"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.listitm}>
                          Disclaimer
                        </Typography>
                      </RouterLink>
                      <RouterLink
                        component={Link}
                        to="/faq"
                        color="inherit"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.listitm}>FAQ</Typography>
                      </RouterLink>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} sm={4} xs={6} align="right">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    maxWidth: "180px",
                  }}
                >
                  <Typography variant="h5" className={classes.testfooter}>
                    Connect with us
                  </Typography>
                </Box>
                <Box className={classes.iconBox} pt={2}>
                  <Box>
                    <a
                      href={
                        youtube?.link
                          ? youtube?.link
                          : "https://www.youtube.com/"
                      }
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      <YouTubeIcon
                        className={classes.listitm}
                        style={{ fontSize: "25px" }}
                      />
                    </a>
                  </Box>
                  <Box>
                    <a
                      href={
                        twitter?.link
                          ? twitter?.link
                          : "https://twitter.com/home?lang=en"
                      }
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      <TwitterIcon
                        className={classes.listitm}
                        style={{ fontSize: "25px" }}
                      />
                    </a>
                  </Box>
                  <Box>
                    <a
                      href={
                        telegram?.link ? telegram?.link : "https://tgwidget.com"
                      }
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      <TelegramIcon
                        className={classes.listitm}
                        style={{ fontSize: "25px" }}
                      />
                    </a>
                  </Box>
                  <Box>
                    <a
                      href={
                        facebook?.link
                          ? facebook?.link
                          : "https://www.facebook.com/"
                      }
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      <FacebookIcon
                        className={classes.listitm}
                        style={{ fontSize: "25px" }}
                      />
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
