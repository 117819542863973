import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Box,
  Container,
  DialogTitle,
  List,
  InputBase,
  Hidden,
  Popper,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

import React, { useState, useEffect, useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "src/context/User";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
// import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Web3 from "web3";
import { getContract } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import { token } from "src/constants";
import abi from "src/abis/Abi2.json";
import { ethers } from "ethers";
import { ListItem } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import Menu from "@material-ui/core/Menu";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Apiconfigs from "src/Apiconfig/Apiconfigs";
import axios from "axios";
const headersData = [
  {
    label: "Home",
    href: "/",
  },

  {
    label: " Gallery ",
    href: "/marketplace",
  },

  {
    label: "Support",
    href: "/faq",
  },
];
const languages = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: "1px 1px 1px 1px lightgrey",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "5px 0",
    "@media (max-width: 1280px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "0",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },

  menuButton: {
    marginTop: "3px",
    size: "18px",
    color: "#35373a",
    fontWeight: 400,
    letterSpacing: "1px",
    borderRadius: 0,
    borderBottom: "2px solid transparent",
    "@media (max-width: 900px)": {
      fontSize: "16px",
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
    "&:hover": {
      color: "#14a800",
      borderColor: "#14a800",
    },
    "& .active": {
      color: "#14a800",
      borderColor: "#14a800",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 0",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#5aa800",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "70px",
    marginBottom: "10px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#4371c31c",
    width: "260px",
  },
  drawericon: {
    color: "#14a800",
    position: "absolute",
    top: "3px",
    right: "-19px",
    fontSize: "25px",
  },
  logoImg: {
    width: "145px",
    "@media (max-width: 900px)": {
      width: "90px",
      paddingRight: "7px",
    },
  },
  menuMobile: {
    color: "#46484b",
    padding: "16px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.75",
    borderBottom: "1px solid #4371c3",
    letterSpacing: "-0.6px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
    // boxShadow: "1px 1px 1px 1px lightgrey",
  },
  typographydia: {
    overflow: "hidden",
    flexWrap: "nowrap",
    width: "50px",
    color: "white",
  },
  diabox: {
    height: "5px",
    backgroundColor: "white",
    borderRight: "2px solid grey",
  },
  diagrid: {},
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  typographyETH: {
    color: "white",
  },
  search: {
    height: "31px",
    width: "300px",
    marginRight: "10px",
    color: "#52565c",
    borderRadius: "100px",
    border: "1px solid #cccccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginTop: "5px",
    "@media (max-width: 900px)": {
      width: "50%",
    },
  },
  DrawerSearch: {
    height: "31px",
    width: "250",
    color: "#52565c",
    borderRadius: "100px",
    border: "1px solid #cccccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    margin: "10px",
    // "@media (max-width: 900px)": {
    //   width: "50%",
    // },
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#52565c",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchInput: {
    color: "#52565c",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    paddingTop: "3px !important",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    lineHeight: "1.1876em",
    width: "100%",
  },
  walletIcon: {
    color: "#46484b",
    height: 40,
    marginLeft: "12px",
    cursor: "pointer",
    display: "flex",
    "@media (max-width: 767px)": {
      marginTop: "17px",
      marginBottom: "17px",
    },

    "&:hover": {
      color: "#14a800",
    },
  },
  langTextFiled: {
    // padding: "14px",
    // marginLeft: "10px",
    width: "13%",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.75",
    // borderBottom: "1px solid #4371c3",
    letterSpacing: "-0.6px",

    // "@media (max-width: 991px)": {
    //   width: "100%",
    // },
    // "@media (max-width: 912px)": {
    //   width: "15%",
    //   // marginLeft: " 11px",
    // },
    "@media (max-width: 820px)": {
      width: "100%",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Header() {
  const [opening, setopening] = React.useState(false);
  const [openloader, setOpenloader] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleCloseloader = () => {
    setOpenloader(false);
  };
  const handleToggle = () => {
    setOpenloader(true);
  };
  const [alertmsg, setalertMsg] = React.useState("");
  const [alertseverity, setalertSeverity] = React.useState("");
  const user = React.useContext(UserContext);
  const [isSubmit, setisSubmit] = React.useState(false);
  const classes = useStyles();
  const { account, library, chainId } = useWeb3React();
  const [numberofnft, setNumberofnft] = React.useState("");
  const [nftprice, setNftprice] = React.useState();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [openalert, setOpenalert] = React.useState(false);

  const handleClickalert = () => {
    setOpenalert(true);
  };

  const handleClosealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenalert(false);
    // setalertMsg({[alertmsg.msg]:"",[alertmsg.severity]:''})
    setalertMsg("");
    setalertSeverity("");
  };
  const [open2, setOpen2] = React.useState(false);

  const [anchorEl6, setAnchorEl6] = React.useState(null);

  const handleClick6 = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClickOpen2 = async (e) => {
    if (typeof account === "undefined") {
      // user.connectWallet();
    } else {
      handleClick(e);
    }
  };
  //wallet 1

  const handleClickOpen1 = async (e) => {
    if (typeof account === "undefined") {
      user.connectWallet();
    } else {
      handleClick1(e);
    }
  };

  const [searchInput, setSearchInput] = useState("");
  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      if (searchInput !== "") {
        history.push({
          pathname: "/search",
          search: searchInput,
        });
        // alert(searchInput);
      } else {
        setSearchInput("");
        history.push({
          pathname: "/search",
          search: searchInput,
        });
        // alert("empty");
        // window.location.href = "/search";
      }
    }
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setNumberofnft(e.target.value);
  };
  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    typographydia,
    diabox,
    typographyETH,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  const [errmsg, seterrmsg] = React.useState("");
  const [errpopup, seterrpopup] = React.useState("");
  const [errhandleClose, seterrhandleClose] = React.useState(false);

  const [errmsg1, seterrmsg1] = React.useState("");
  const [errpopup1, seterrpopup1] = React.useState("");
  const [errhandleClose1, seterrhandleClose1] = React.useState(false);

  const userType = sessionStorage.getItem("userType");

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const DisconnectWallet = () => {
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userAddress");

    toast.success("Disconnect Successful");

    window.location.href = "/";
  };
  const displayDesktop = () => {
    return (
      <Container>
        <Toolbar className={`${toolbar} topbarNew`}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}
            {/* {user.userLoggedIn && femmecubatorLogo1} */}
          </Grid>
          {/* <div>{getMenuButtons()}</div> */}
        </Toolbar>
      </Container>
    );
  };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "5px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));

    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    const handleChange5 = (event) => {
      setCurrency(event.target.value);
    };

    return (
      <Toolbar>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/ccc.gif" alt="" />
            {getDrawerChoices()}
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box className={classes.DrawerSearch}>
                <Box className={classes.searchIcon}>
                  <SearchIcon />
                </Box>
                <InputBase
                  type="search"
                  className={classes.searchInput}
                  placeholder="Search by name"
                  onKeyDown={onKeyDown}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Box>
            </Box>

            {account ? (
              <Box style={{ display: "flex" }}>
                {" "}
                <Avatar
                  alt=""
                  src={
                    user?.userData?.profilePic
                      ? user?.userData?.profilePic
                      : "/broken-image.jpg"
                  }
                  onClick={(e) => handleClickOpen2(e)}
                  className={classes.walletIcon}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose1}
                >
                  <MenuItem>
                    {" "}
                    {account &&
                      `${account.substring(0, 4)}...${account.substring(
                        account.length - 4,
                        account.length
                      )}`}{" "}
                    <CopyToClipboard text={account}>
                      <FiCopy onClick={() => toast.info("Copied")} />
                    </CopyToClipboard>
                  </MenuItem>

                  <MenuItem
                    onClick={() => DisconnectWallet()}
                    style={{ color: "red" }}
                  >
                    Disconnect
                  </MenuItem>
                </Menu>
                <AccountBalanceWalletIcon
                  onClick={(e) => handleClickOpen1(e)}
                  className={classes.walletIcon}
                />
              </Box>
            ) : (
              <Box style={{ paddingLeft: "6px", paddingTop: "10px" }}>
                <Button
                  onClick={(e) => handleClickOpen1(e)}
                  style={{
                    background: "#4371c3",
                    padding: "5px 34px 5px 34px",
                    color: "#fff",
                    borderRadius: "21px",
                  }}
                >
                  Connect
                </Button>
              </Box>
            )}
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push("/profile")}>
                <span
                  style={{ fontWeight: "400", color: "rgb(53 55 58 / 84%)" }}
                >
                  My Profile
                </span>
              </MenuItem>
              <MenuItem onClick={() => history.push("/my-nft")}>
                <span
                  style={{ fontWeight: "400", color: "rgb(53 55 58 / 84%)" }}
                >
                  My NFT
                </span>
              </MenuItem>
              {/* <MenuItem onClick={() => DisconnectWallet()}>Disconnect</MenuItem> */}
            </StyledMenu>
          </div>
        </Drawer>
        <div>{femmecubatorLogo}</div>

        <IconButton
          className={drawericon}
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon width="60px" height="60px" />
        </IconButton>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem className={menuMobile}>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Logo
        width="60"
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/")}
      />
    </Box>
  );

  const [currency, setCurrency] = React.useState("English");

  const femmecubatorLogo1 = (
    <Box>
      {account ? (
        <Button
          color="inherit"
          className={menuButton}
          to="/create-nft"
          component={RouterLink}
        >
          Create
        </Button>
      ) : (
        ""
      )}
    </Box>
  );

  const getMenuButtons = () => {
    const handleChange5 = (event) => {
      setCurrency(event.target.value);
    };
    // const handleClickOpen = () => {
    //   setopening(true);
    // };
    return (
      <Box style={{ display: "flex" }}>
        <Hidden xsDown>
          <Box className={classes.search}>
            <Box className={classes.searchIcon}>
              <SearchIcon />
            </Box>
            <InputBase
              type="search"
              className={classes.searchInput}
              // placeholder="Search Works, Promotions"
              placeholder="Search by name"
              onKeyDown={onKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
        </Hidden>

        {headersData.map(({ label, href }) => {
          return (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                className: menuButton,
              }}
            >
              {label}
            </Button>
          );
        })}

        {userType === "ADMIN" && femmecubatorLogo1}

        <Backdrop className={classes.backdrop} open={openloader}>
          <CircularProgress color="primary" />
        </Backdrop>

        {account ? (
          <>
            <Avatar
              alt=""
              src={
                user?.userData?.profilePic
                  ? user?.userData?.profilePic
                  : "/broken-image.jpg"
              }
              onClick={(e) => handleClickOpen2(e)}
              className={classes.walletIcon}
            />
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose1}
            >
              <MenuItem>
                {" "}
                {account &&
                  `${account.substring(0, 4)}...${account.substring(
                    account.length - 4,
                    account.length
                  )}`}
                &nbsp;
                <CopyToClipboard text={account}>
                  <FiCopy onClick={() => toast.info("Copied")} />
                </CopyToClipboard>
              </MenuItem>

              <MenuItem
                onClick={() => DisconnectWallet()}
                style={{ color: "red" }}
              >
                Disconnect
              </MenuItem>
            </StyledMenu>
            <AccountBalanceWalletIcon
              onClick={(e) => handleClickOpen1(e)}
              className={classes.walletIcon}
            />
          </>
        ) : (
          <Box>
            <Button
              onClick={(e) => handleClickOpen1(e)}
              style={{
                background: "#4371c3",
                padding: "5px 34px 5px 34px",

                color: "#fff",
                borderRadius: "21px",
              }}
            >
              Connect
            </Button>
          </Box>
        )}

        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push("/profile")}>
            <span style={{ fontWeight: "400", color: "rgb(53 55 58 / 84%)" }}>
              My Profile
            </span>
          </MenuItem>
          <MenuItem onClick={() => history.push("/my-nft")}>
            <span style={{ fontWeight: "400", color: "rgb(53 55 58 / 84%)" }}>
              My NFT
            </span>
          </MenuItem>
          {/* <MenuItem onClick={() => DisconnectWallet()}>Disconnect</MenuItem> */}
        </StyledMenu>
        <Box>
          <Dialog
            open={opening}
            className="walletdailog"
            onClose={() => {
              setopening(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="dailogTitle">
              {"Select a Wallet"}
            </DialogTitle>
            <DialogContent className="loginModal">
              <DialogContentText id="alert-dialog-description">
                <Box className="walletlist">
                  <List>
                    <ListItem>
                      <Link>
                        <Typography variant="h3">Metamask</Typography>
                        <img src="/images/metamask.png" alt="metamask" />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">
                        <Typography variant="h3">Wallet Connect</Typography>
                        <img src="/images/wt.png" alt="metamask" />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">
                        <Typography variant="h3">Binance smart</Typography>
                        <img src="/images/binance.png" alt="metamask" />
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dailogFooter">
              <a onClick={() => setopening(false)} color="primary" autoFocus>
                Close
              </a>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    );
  };
  return (
    <Container maxWidth="lg">
      <AppBar
        className={header}
        elevation={0}
        style={
          history.location.pathname !== "/"
            ? { paddingLeft: "0px", paddingRight: "0px" }
            : null
        }
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "xl" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>

        <Snackbar
          open={errpopup}
          autoHideDuration={5000}
          onClose={errhandleClose}
        >
          <Alert onClose={errhandleClose} severity="info">
            {errmsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errpopup1}
          autoHideDuration={5000}
          onClose={errhandleClose}
        >
          <Alert onClose={errhandleClose1} severity="info">
            {errmsg1}
          </Alert>
        </Snackbar>
      </AppBar>
    </Container>
  );
}
