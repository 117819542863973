//******************************* UAT ************************************************ */

// export const NetworkContextName = "Kovan Test Network";
// export const ACTIVE_NETWORK = 42;
// export const token = "0x69c978A8d81044abb5565A488B55531313eCE049";

// export const RPC_URL =
//   "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

// export const NftTokenAddress = "0x29de27B425162360604c04866CA7A0Da7a3FeB1f"; //NFT token address
// export const OpenMarketplace = "0x8fe3F4ee2dC7c5971c1Aaf3BaCC2Ae0d43D198C7"; //marketplace
// export const AcceptedToken = "0x8694F8Dd1683cEf516d5ccFa4E843471709184D3"; //CCC
// export const currancyTokenETH = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

//******************************* UAT ************************************************ */

export const NetworkContextName = "Binance Smart Testnet";
export const ACTIVE_NETWORK = 97;

// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";

export const NftTokenAddress = "0xCc28aE0368cd26901f5023AcE874050D509d4235"; //NFT token address
export const OpenMarketplace = "0x655A411B0dB8A47209a64dC2C2aee461C4FEc3b0"; //marketplace
export const AcceptedToken = "0xA2032241D167DA3f9027e8E841ac657C15695838"; //CCCc
export const currancyTokenETH = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

//******************************* Client ************************************************ */

// export const NetworkContextName = "Binance Smart Chain";
// export const ACTIVE_NETWORK = 56;

// export const RPC_URL = "https://bsc-dataseed.binance.org/";

// export const NftTokenAddress = "0x3A444C2Ea7931Bd8242cD1a45D27cB71C36a7690"; //NFT token address
// export const OpenMarketplace = "0xe5Cd8c0F34fE4aB3529c7EC7b812bfa78B9cE7f1"; //marketplace
// export const AcceptedToken = "0xd8dD5E8e0235f2ebF1eBD73a68e4f3515a7afCC1"; //CCC
// export const currancyTokenETH = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

//******************************* Client ************************************************ */

export const getNetworkDetails = (chianId) => {
  switch (chianId?.toString()) {
    case "42":
      return [
        {
          chainId: "0x6c4",
          chainName: "kovan",
          nativeCurrency: {
            name: "kovan",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://kovan.infura.io/v3/"],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s3.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
    case "56":
      return [
        {
          chainId: "0x61",
          chainName: "Binance Smart Chain",
          nativeCurrency: {
            name: "Binance Smart Chain",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed.binance.org/"],
          blockExplorerUrls: ["https://bscscan.com"],
        },
      ];
  }
};
