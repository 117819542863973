// const url = "https://node.iccc.co";
const url = "https://node-nft-marketplace-vincent.mobiloitte.org";

//vishnu
// const url = "http://172.16.1.132:1859";
// anupriya;
// const url = "http://172.16.2.13:1859";

const Apiconfigs = {
  addimage: `${url}/api/v1/nft/addImage`,
  uploadft: `${url}/api/v1/nft/uploadNFT`,
  // addNft: `${url}/api/v1/nft/addNft`,
  listnft: `${url}/api/v1/admin/listNft`,
  placeorderlist: `${url}/api/v1/nft/placeOrderList`,
  placeorderlistbyid: `${url}/api/v1/nft/placeOrderListById/`,
  sellMyOrderList: `${url}/api/v1/nft/sellMyOrderList`,
  placebid: `${url}/api/v1/nft/placeBid`,
  acceptbid: `${url}/api/v1/nft/acceptBid`,
  rejectBid: `${url}/api/v1/nft/rejectBid`,
  orderListParticular: `${url}/api/v1/nft/orderListParticular`,
  bidList: `${url}/api/v1/nft/bidList`,
  listStaticContent: `${url}/api/v1/static/listStaticContent`,
  //USER DASHBOARD
  connectwallet: `${url}/api/v1/user/connectWallet`,
  getprofile: `${url}/api/v1/user/getProfile`,
  editprofile: `${url}/api/v1/user/editProfile`,
  listAllCreatedNft: `${url}/api/v1/user/listAllCreatedNft`,
  importNft: `${url}/api/v1/user/importNft`,

  //user

  cancelPlaceOrder: `${url}/api/v1/admin/cancelPlaceOrder`,
  placeOrderList: `${url}/api/v1/admin/placeOrderList`,
  listNft: `${url}/api/v1/admin/listNft`,
  listUser: `${url}/api/v1/admin/listUser`,
  sellOrderList: `${url}/api/v1/nft/sellOrderList`,
  addNft: `${url}/api/v1/admin/addNft`,
  placeorder: `${url}/api/v1/admin/placeOrder`,
  placeOrderByUser: `${url}/api/v1/nft/placeOrderByUser`,
  // sellOrderList: `${url}/api/v1/nft/sellOrderList`,/api/v1/user/dashboard
  dashboard: `${url}/api/v1/user/dashboard`,

  viewNft: `${url}/api/v1/user/viewNft`,
  getStaticContent: `${url}/api/v1/static/getStaticContent`,
  getLink: `${url}/api/v1/static/getLink`,
  //New api
  getLink: `${url}/api/v1/static/getLink`,
  contactUs: `${url}/api/v1/user/contactUs`,
  viewNftTransaction: `${url}/api/v1/admin/viewNftTransaction/`,
  viewPlaceOrder: `${url}/api/v1/admin/viewPlaceOrder`,
  placeOrderListExpo: `${url}/api/v1/admin/placeOrderListExpo`,
  buyOrder: `${url}/api/v1/nft/buyOrder`,
  expiredOrder: `${url}/api/v1/nft/expiredOrder`,
  buyOrderList: `${url}/api/v1/nft/buyOrderList`,
  myNftList: `${url}/api/v1/user/myNftList`,
  buyOrderListById: `${url}/api/v1/nft/buyOrderListById`,
  viewnft: `${url}/api/v1/admin/viewNft/`,
  placeOrderListt: `${url}/api/v1/nft/placeOrderList`,
  myOrderList: `${url}/api/v1/nft/myOrderList`,
  transferRemoveNft: `${url}/api/v1/nft/transferRemoveNft`,

  //Static Management

  listFAQ: `${url}/api/v1/faq/listFAQ`,
  // listStaticContent: `${url}/api/v1/static/listStaticContent`,
};

export default Apiconfigs;
