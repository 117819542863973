import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ERC } from "src/abis/IERC20ABI";
import axios from "axios";
import Apiconfigs from "../Apiconfig/Apiconfigs";
import { toast } from "react-toastify";
import { AcceptedToken } from "src/constants/index";
import { getBalanceOf } from "src/utils";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

const setUserType = (userType) => {
  if (userType) {
    sessionStorage.setItem("userType", userType);
  } else {
    sessionStorage.removeItem("userType");
  }
};
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [editProfile12, seteditProfile12] = useState();
  const [balanceOfValue, setBalanceOfValue] = useState(0);

  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
    isLogin,
    userData,
    balanceOfValue,
    getProfileHandler: (token) => getProfileHandler(token),
  };

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      // toast.success("Connected Successful");
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(await getBalanceOf(ERC, AcceptedToken, account));
  }

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(Apiconfigs.connectwallet, {
        walletAddress,
      });
      if (res.data.response_code === 200) {
        getProfileHandler(res.data.result.token);

        setTokenSession(res.data.result.token);
        setUserType(res.data.result.userType);
        setIsLogin(true);
      } else {
        deactivate();
        setIsLogin(false);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      if (error.response) {
        toast.error(error.response.data.response_message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(Apiconfigs.getprofile, {
        headers: {
          token,
        },
      });
      if (res.data.response_code === 200) {
        setUserData(res.data.result);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      const NetworkDetails = getNetworkDetails(ACTIVE_NETWORK);

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
