export default {
  h1: {
    fontWeight: 600,
    fontSize: 35,
    fontFamily: "'Roboto', sans-serif",
  },
  h2: {
    fontSize: 30,
    fontFamily: "'Roboto', sans-serif",
    fontSize: "55px",
    lineHeight: "65px",
    textAlign: "left",
    color: "rgb(67, 113, 195)",
    fontWeight: "700",
  },
  h3: {
    color: "#14a800",
    fontSize: "35px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
    lineHeight: "40px",
    "@media (max-width: 767px)": {
      fontSize: "25px",
    },
  },
  h4: {
    fontSize: "25px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
    lineHeight: "35px",
    color: "#4371c3",
    "@media (max-width: 767px)": {
      fontSize: "20px",
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "'Roboto', sans-serif",
    color: "#14a800",
  },
  h6: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "'Roboto', sans-serif",
    "@media (max-width: 767px)": {
      fontSize: "13px",
    },
  },
  overline: {
    fontWeight: 600,
    fontFamily: "'Roboto', sans-serif",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Roboto', sans-serif",
  },
};
